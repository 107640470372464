<template>
    <div class="bank_account_container">
      <input
        v-for="item in bank_type"
        :key="item.id"
        ref="referraBankInput"
        @keyup="handleKeyUp"
        :disabled="disabled"
        class="bank_input"
        :id="'bankinput' + item.id"
        v-enterNumber
        type="text"
        :maxlength="item.size"
        :size="item.size"
        @change="handleInpuChange"
        >
    </div>
</template>

<script>
export default {
  created () {
  },
  data () {
    return {
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    account: {
      type: String,
      default: '---'
    },
    bank_type: {
      type: Array,
      default: () => [
        {
          id: 0,
          size: 2
        },
        {
          id: 1,
          size: 4
        },
        {
          id: 2,
          size: 7
        },
        {
          id: 3,
          size: 3
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    handleInpuChange () {
      this.$emit('handleInpuChange', this.$refs.referraBankInput.map(({ value }) => value).join('-'))
    },
    handleKeyUp (e) {
      const { keyCode } = e
      const { value, maxLength, id } = e.target
      if (keyCode !== 8) {
        // keyCode: 8是删除键
        const nextId = parseInt(id.replace(/[^0-9]/ig, '')) + 1
        if (nextId < 5) {
          if (value.length === maxLength) {
            if (nextId < this.bank_type.length) {
              this.$refs.referraBankInput[nextId].focus()
            }
          }
        }
      } else {
        const nextId = parseInt(id.replace(/[^0-9]/ig, '')) - 1
        if (nextId >= 0) {
          if (value.length === 0) {
            this.$refs.referraBankInput[nextId].focus()
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.bank_account_container{
  display: flex;
  gap: 6px;
}
.bank_input {
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 4px 11px;
    outline: none;
    font-weight: 500;
}
.bank_input:disabled{
  background-color: #f5f5f5;
  color: rgba(0,0,0,.25);
  cursor:not-allowed
}
.bank_input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
</style>
